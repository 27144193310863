module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"danvirgil.com","short_name":"danvirgil.com","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"browser","icon":"src/images/icon.png","icons":[{"src":"/favicons/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"badfbf5b4e9dfb74d3e6c5a25c35ce2a"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
